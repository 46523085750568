import { join } from 'path';
import * as React from 'react';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import { renderRichText } from '../utils/rich-editor';
import { Language } from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { FixedObject } from 'gatsby-image';

type Props = {
  data: {
    contentfulWork: {
      title: string;
      slug: string;
      content: {
        json: Document;
      };
      node_locale: Language;
      seoText: { seoText: string };
      featuredImage: { fixed: FixedObject };
    };
  };
  location: Location;
};

function Work({
  data: {
    contentfulWork: {
      featuredImage,
      seoText,
      title,
      slug,
      content,
      node_locale,
    },
  },
  location,
}: Props) {
  return (
    <Layout
      lang={node_locale}
      location={location}
      pathnameWithoutLang={join(models.dynamic.work.path, slug)}
    >
      <SEO
        title={`${title} - Works`}
        description={seoText.seoText}
        lang={node_locale}
        featuredImage={featuredImage?.fixed?.src}
      />
      <article className="entry">
        <div className="container">
          <h1 className="entry__title">{title}</h1>
          <div className="entry__body">
            <div>
              <div>
                {renderRichText({ document: content.json, lang: node_locale })}
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}

export default Work;

export const pageQuery = graphql`
  query workQuery($id: String!) {
    contentfulWork(id: { eq: $id }) {
      title
      content {
        json
      }
      slug
      node_locale
      seoText {
        seoText
      }
      featuredImage {
        fixed(width: 1200) {
          src
          srcSet
        }
      }
    }
  }
`;
